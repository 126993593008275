<template>
  <!-- <MaintenanceMode /> -->
  <router-view />
  <a
    href="https://wa.me/250782336854"
    target="_blank"
    rel="noopener noreferrer"
    class="whatsapp-link"
  >
    <img src="img/tspwithus.png" style="padding: 10px;" width="300px"/>
  </a>
</template>
<script>
// import MaintenanceMode from './views/RedirectView.vue'
export default {
  components: {
    // MaintenanceMode
  }
}
</script>
<style scoped>
.whatsapp-link {
  position: fixed;
  bottom: 0px;
  right: 0px;
  /* background-color: green; */
  color: white;
  padding: 5px;
  border-radius: 50%;
}
</style>
