<template>
  <section id="footer" class="pt-3 pb-3">
    <div class="container-xl">
      <div class="row footer_1">
        <div class="col-md-4">
          <div class="footer_1i">
            <h4>
              <a
                ><i class="fa fa-car col_oran"></i> Kigali Rwanda Car Hire
                <span class="fw-normal"></span></a
              >
            </h4>
            <p class="mt-3">
              Welcome to Discover Kigali Rwanda Car Hire, your premier choice for reliable and comfortable transportation solutions in the heart of Rwanda
            </p>
            <h6 class="mt-3 fw-normal">
              <i class="fa fa-map col_oran me-1"></i> Giporoso, Remera Kigali, Rwanda
            </h6>
            <h6 class="mt-3 fw-normal">
              <a href="#" @click="bookCar();"
                ><i class="fa fa-phone col_oran me-1"></i> (+250) 782 336 854</a
              >
            </h6>
            <h6 class="mt-3 mb-0 fw-normal">
              <a href="#"
                ><i class="fa fa-envelope col_oran me-1"></i> info@kigalirwandacarhire.com</a
              >
            </h6>
          </div>
        </div>
        <div class="col-md-2">
          <div class="footer_1i">
            <h4>COMPANY</h4>
            <div class="row">
              <h6 class="fw-normal mt-2 col-md-12 col-6">
                <a href="#">Remera, Kigali</a>
              </h6>
              <h6 class="fw-normal mt-2 col-md-12 col-6">
                <a>Careers</a>
              </h6>
              <h6 class="fw-normal mt-2 col-md-12 col-6">
                <a href="/about">About Us</a>
              </h6>
              <h6 class="fw-normal mt-2 col-md-12 col-6">
                <a href="/car-listing">Cars Model</a>
              </h6>
              <h6 class="fw-normal mt-2 mb-0 col-md-12 col-6">
                <a href="/contact">Contact US</a>
              </h6>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="footer_1i">
            <h4>WORK HOURS</h4>
            <p class="mt-3">
              Mon - Fri:
              <span class="fw-bold text-black">08:00AM - 18:00PM</span>
            </p>
            <p class="mt-3">
              Sat: <span class="fw-bold text-black">Closed</span>
            </p>
            <p class="mt-3 mb-0">
              Sun: <span class="fw-bold text-black">08:00AM - 18:00PM</span>
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="footer_1i">
            <h4>SUBSCRIPTION</h4>
            <p class="mt-3">
              Subscribe your Email address for latest news & updates.
            </p>
            <input
              class="form-control"
              placeholder="Enter Email Address"
              type="text"
            />
            <h6 class="mb-0 mt-4">
              <a class="button pt-3 pb-3" href="#"
                >Submit <i class="fa fa-check-circle ms-1"></i>
              </a>
            </h6>
          </div>
        </div>
      </div>
      <hr />
      <div class="row footer_2">
        <div class="col-md-8">
          <div class="footer_2l">
            <p class="mb-0 mt-1">
              © 2024 Kigali Rwanda Car Hire. All Rights Reserved
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="footer_2r text-end">
            <ul class="social-network social-circle mb-0">
              <li>
                <a href="#" class="icoRss" title="Rss"
                  ><i class="fa fa-rss"></i
                ></a>
              </li>
              <li>
                <a href="#" class="icoFacebook" title="Facebook"
                  ><i class="fa fa-pinterest"></i
                ></a>
              </li>
              <li>
                <a href="#" class="icoTwitter" title="Twitter"
                  ><i class="fa fa-twitter"></i
                ></a>
              </li>
              <li>
                <a href="#" class="icoLinkedin" title="Linkedin"
                  ><i class="fa fa-linkedin"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    bookCar () {
      confirm('Are you sure you want to book this car now ?') ? this.confirmBooking() : this.cancelBooking()
    },
    cancelBooking () {
      alert('Booking Car not yet done, Pleases try again')
    },
    confirmBooking () {
      const title = 'Request to Book a CAR TO kigalirwandacarhire.com \n ----------------------------------- \n'
      const phoneNumber = '250788497607'
      const names = '.....................'
      const email = '.....................'
      const customerPhone = '..............'
      const bookDescription = '..................................'
      const message = title + ' CAR NAME: ' + this.carName + '\n Your Names' + names + '\n Your Email: ' + email + '\n Your Phone Number: ' + customerPhone + '\n  Special Request: ' + bookDescription
      const encodedMessage = encodeURIComponent(message)
      const link = `https://wa.me/${phoneNumber}?text=${encodedMessage}`
      window.open(link, '_blank')
    }
  }
}
</script>
